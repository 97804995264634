import color_bg from './color_bg.jpg';
import me from './me.jpg';
import github from './GitHub.png';
import linkedin from './LI-In.png';
import email from './email.png';
import meeple from './meeple.png';
import bg_wall from './bg_wall.jpeg';
import autolearn from './Autolearn.png';
import soon from './soon.png';
import gofish from './gofish.png';

const images = {
  color_bg,
  me,
  github,
  linkedin,
  email,
  meeple,
  bg_wall,
  autolearn,
  soon,
  gofish
};

export default images;
